<template>
    <div class="recipes-all page">
        <div class="recipes-all__featured-recipe" v-if="featuredRecipe != null">
            <b-container fluid="xl">
                <b-row>
                    <b-col cols="12" md="6" lg="4" xl="3" v-if="recipeLead != null" class="text-wrap">
                        <div v-html="recipeLead.description" v-inview="'fade-from-left'"></div>
                    </b-col>
                    <b-col cols="12" md="6" lg="8" offset-xl="1">
                        <RecipeCard :recipe="featuredRecipe" v-inview="'fade-from-right'"/>
                    </b-col>
                </b-row>
            </b-container>            
        </div>
        <div class="recipes-all__categories recipe-offer__categories-row" id="recept-szuro" >
            <router-link 
                :to="$route.path +'#osszes'" 
                class="recipe-category-item" 
                v-inview="'fade-up'"
                :class="{'active': selectedRecipeCategorySlug == 'osszes'}"
            >
                <div>
                    <img src="@/assets/images/gasztro/duhaj_badge.png" />
                    <div class="name">Összes</div>
                </div>                            
            </router-link>
            <RecipeCategoryItem 
                v-for="category in recipeCategories" :key="category.id" 
                :category="category"             
                :isActive="selectedRecipeCategorySlug!= 'osszes' && selectedRecipeCategorySlug == category.slug"    
            />
        </div>
        <div class="recipes-all__recipes-wrap">
            <b-container>     
                <!-- Recipe items -->
                <b-row class="recipe-cards-wrap row" v-if="recipes.length>0">
                    <b-col cols="12" md="6" v-for="(recipe,rindex) in recipes" :key="recipe.id">
                        <div class="recipe-item-wrap" v-if='rindex < currentEnd'>
                            <RecipeCard :recipe="recipe" v-inview="'fade-up'"/>
                        </div>                                              
                    </b-col>                                     
                </b-row>      
                <div class="btn-wrap d-flex justify-content-center" v-if='recipes.length>recipeListStep && currentEnd < recipes.length'>                
                    <button                             
                        class="btn btn--gold-border btn--with-arrow btn--show-more"
                        @click="showMore()"
                        v-inview="'fade-up'"
                    >
                        <span>mutass többet</span>
                    </button>               
                </div>            
            </b-container>
        </div>
        <section class="other-pdf-products" v-inview="'fade-up'">
            <h3>termékcsaládjaink</h3>
            <ProdFamilySlider :needApiCall="true" :prodFamiliesAll="[]"/>
        </section>
        <PageLoading  /> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'

import RecipeCategoryItem from '@/components/pages/recipe/RecipeCategoryItem'
import RecipeCard from '@/components/pages/recipe/RecipeItem.vue'
import ProdFamilySlider from '@/components/pages/productFamily/ProdFamilySlider.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    mixins: [apiH,viewMI],
    data(){
        return{
            recipeCategories:[],
            recipesAll:[],
            recipes:[],
            recipeLimit: 8,
            currentEnd: 8,
            recipeListStep:8, 
            featuredRecipeId: null,
            featuredRecipe: null,
            modulesIdentifiers: 'gasztro_lead',
            recipeLead: null,
            selectedRecipeCategorySlug: 'osszes'            
        }
    },
    components:{
        RecipeCategoryItem,
        RecipeCard,
        ProdFamilySlider,
        PageLoading
    },
    // computed:{
    //     idFromHash(){
    //         return this.$route.hash ? this.$route.hash.replace("#",'') : 'recipe-category'
    //     }
    // },
    created(){        
        this.getFeaturesRecipeId()
        // this.getRecipeCategories()            
        this.getRecipes()
        this.getLeadText()           
    },
    mounted(){
        let _this = this

        if (window.innerWidth < 767){
            _this.recipeLimit = 4
            _this.currentEnd = 4
            _this.recipeListStep = 4
        }

        //Meta tags
        this.setMetaTags({
            title: 'Soproni - Gasztro', 
            desc: null, 
            keywords: null, 
            ogType: 'website', 
            ogTitle: 'Soproni - Gasztro',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:null
        })
    },
    watch:{
        $route(){                        
            this.selectedRecipeCategory = 'osszes'   
            this.getFilterFromHash();            
        }
    },
    methods:{
        getFeaturesRecipeId(){            
            let _this = this

            _this.get('config/setting/config_featured_recipe_id',
                {                   
                },
                function(resp){                                        
                   _this.featuredRecipeId = resp.data.value
                    
                }, function(err){
                    console.log(err);
                    _this.featuredRecipeId = null
                }
            )
        },
        getFeaturesRecipe(){
            let _this = this

            if (_this.featuredRecipeId != null || _this.featuredRecipeId != undefined || _this.eaturedRecipeId != ''){
                _this.featuredRecipe = _this.recipesAll.find( item => parseInt(item.id) === parseInt(_this.featuredRecipeId))
            } else {
                _this.featuredRecipe = null
            }

        },
        getRecipes(){
            let _this = this

            _this.recipes = []
            _this.recipesAll = []
            _this.recipeCategories = []

            _this.get('recipe',
                {
                    params: {                          
                        relations:1,                                            
                        where: [
                            ['where','status', '1'],
                            ['where','image!=', '0'],
                        ],
                        order_by:[['sort_order', 'asc'],['created_at', 'desc']]                               
                    }
                },
                function(resp){                                        
                    _this.recipesAll = resp.data.recipe    
                    
                    //collect the categories
                    _this.recipesAll.forEach( element => {                            

                        if (_this.recipeCategories.length === 0){                            
                            _this.recipeCategories.push(element.recipe_category)                        
                        } else if ( element.recipe_category && element.recipe_category_id != 0) {
                            if( _this.recipeCategories.find( item => parseInt(item.id) === parseInt(element.recipe_category.id)) == undefined ){                            
                            _this.recipeCategories.push(element.recipe_category)
                            }
                        } 
                    })

                    if (_this.recipesAll.length > 0){
                        //select the recipes from the url if it has # value
                        _this.getFilterFromHash()  
                    } else {
                        _this.recipes = []
                    }

                    //set the featured recipes
                    _this.getFeaturesRecipe()
                    
                    
                }, function(err){
                    console.log(err);
                    _this.recipes = []
                    _this.recipesAll = []
                    _this.recipeCategories = []
                }
            )
            
        },           
        getFilterFromHash : function(){
            var filter_slug = this.$route.hash.replace("#",'');
            let _this = this
            // console.log('hash',filter_slug);

            if (filter_slug == "osszes" || filter_slug == '' || filter_slug == null || filter_slug == undefined){                
                _this.recipes = _this.recipesAll;  
                _this.selectedRecipeCategorySlug = 'osszes'            
            } else {                
                let selectedRecipeCategory = _this.recipeCategories.find( item => item.slug == filter_slug)      
                _this.selectedRecipeCategorySlug = selectedRecipeCategory.slug    
                _this.recipes = _this.recipesAll.filter(item => item.recipe_category_id === selectedRecipeCategory.id)
            }                        

        },  
        getLeadText(){
            var _this = this               

            _this.get('module',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],
                            ['where','identifier', _this.modulesIdentifiers],                      
                        ]                    
                    }
                },
                function(resp){                                                 
                    _this.recipeLead = resp.data.module[0]                                                  
                }, function(err){   
                    console.log(err)                   
                    _this.recipeLead = []                  
                }              
            )      
            
        },
        showMore(){
            this.currentEnd += this.recipeListStep  
            this.setGtm()          
        },   
        setGtm(){
            var filter_slug = this.$route.hash.replace("#",'');
            let text = ''

            if ( filter_slug == 'osszes'){
                text = 'osszes'
            } else {
                text = filter_slug
            }


            this.GTtrackGA4({
                'event' : 'event',
                'category': 'gasztro_listaoldal',
                'action': 'mutass_tobbet_gombra_kattintas',                
                'page_type': 'gasztro_lista',
                'button': 'mutass_tobbet',            
                'clicked_text': text,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>